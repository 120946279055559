
































import { Vue, Component, Prop } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { LoaderComponent } from 'vue-loading-overlay';
import { GridReadyEvent, ValueGetterParams } from '@ag-grid-community/core';
import { AgGridVue } from '@ag-grid-community/vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import ConversationService from '@/services/crm/conversation.service';
import ConversationModel from '@/models/crm/conversation.model';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import { IGridCellEvent } from '@/interfaces/grid-cell-clicked.interface';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { GridHelper } from '@/utils/helpers/grid-helper';
import { DateHelper } from '@/utils/helpers/date-helper';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import { IKeyValue } from '@/interfaces/key-value.interface';
import ContentDialog from '@/components/content-dialog.vue';
import CrmChatHistoryMessagesViewer from '@/components/crm/chat-history-messages-viewer.vue';

@Component({
  components: {
    AgGridWrapper,
    AgGridVue,
    ContentDialog,
    CrmChatHistoryMessagesViewer,
  },
})
export default class CrmChatHistoryViewer extends Vue {
  @inject(InjectionIdEnum.CrmConversationService)
  private conversationService!: ConversationService;

  @Prop({ required: true })
  conversation!: ConversationModel;

  grid: GridReadyEvent | null = null;

  gridSettings: IGridConfig = {
    loading: false,
    defaultSort: [{ colId: 'dataInicio', sort: 'desc' }],
    itemsPerPage: 999,
    columnDefs: [
      {
        headerName: `${this.$t('crm.chatHistoryViewer.grid.protocol')}`,
        field: 'protocolo',
        maxWidth: 135,
      },
      {
        headerName: `${this.$t('crm.chatHistoryViewer.grid.attendant')}`,
        field: 'atendente.nome',
        flex: 0.7,
      },
      {
        headerName: `${this.$t('crm.chatHistoryViewer.grid.startDate')}`,
        field: 'dataInicio',
        maxWidth: 135,
        valueGetter: (params): string => GridHelper.valueGetter(params, DateHelper.formatToIsoDateTimeString),
        valueFormatter: (params): string => DateHelper.formatToLocale(params.value, 'dateTime'),
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.chatHistoryViewer.grid.closeDate')}`,
        field: 'dataFechamento',
        maxWidth: 150,
        valueGetter: (params): string => GridHelper.valueGetter(params, DateHelper.formatToIsoDateTimeString),
        valueFormatter: (params): string => DateHelper.formatToLocale(params.value, 'dateTime'),
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.chatHistoryViewer.grid.type')}`,
        field: 'tipoHistorico.nome',
        flex: 1,
      },
      {
        headerName: `${this.$t('crm.chatHistoryViewer.grid.consolidateSale')}`,
        field: 'efetuouVenda',
        maxWidth: 100,
        valueGetter: (params: ValueGetterParams): string => {
          const value = GridHelper.valueGetter(params) as number;
          const key = value === 1 ? 'yes' : 'no';
          return `${this.$t(`global.${key}`)}`;
        },
      },
    ],
  };

  items: ConversationModel[] = [];

  dialogConfig: IKeyValue<IDialogConfig> = {
    messages: {
      show: false,
      conversation: null,
    },
  };

  async mounted(): Promise<void> {
    const loader = this.setBusyLoader();
    try {
      if (!this.conversation.numeroWhatsapp) {
        this.$notify.error('crm.chatHistoryViewer.messages.couldNotFoundWhatsappNumber');
      }

      this.items = await this.conversationService.getCloseConversations(this.conversation.numeroWhatsapp);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  onCellClick(event: IGridCellEvent<ConversationModel>): void {
    if (!event.data) {
      return;
    }

    this.dialogConfig.messages.conversation = event.data;
    this.dialogConfig.messages.show = true;
  }

  onMessageDialogClose(): void {
    this.dialogConfig.messages.show = false;
    this.dialogConfig.messages.conversation = null;
  }

  get messagesDialogTitle(): string {
    const protocol = (this.dialogConfig.messages.conversation as ConversationModel)?.protocolo;
    return this.$t('crm.chatHistoryViewer.dialog.messages.title', { protocol }).toString();
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.chatViewerContainer,
      canCancel: false,
    });
  }
}
