















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { isArray, last } from 'lodash';
import dayjs from '@/plugins/dayjs';
import ConversationMessageModel from '@/models/crm/conversation-message.model';
import { ConversationMessageTypeEnum } from '@/enums/crm/conversation-message-type.enum';
import { ConversationMessageOriginEnum } from '@/enums/crm/conversation-message-origin.enum';
import { ConversationMessageStatusEnum } from '@/enums/crm/conversation-message-status.enum';
import ConversationModel from '@/models/crm/conversation.model';
import { ConversationTypeEnum } from '@/enums/crm/conversation-type.enum';
import ConversationTemplateModel from '@/models/crm/conversation-template.model';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { ClientTypeEnum } from '@/enums/client-type.enum';

@Component({})
export default class CrmChatListItem extends Vue {
  @Prop()
  loading!: boolean;

  @Prop({ required: true })
  conversation!: ConversationModel;

  @Prop({ default: 0 })
  unreadCounter!: number;

  @Prop({ default: 'company_name' })
  additionalInfoType!: 'company_name' | 'city';

  @Prop({ default: () => [] })
  templates!: ConversationTemplateModel[];

  get clientName(): string {
    let client = '';
    if (this.conversation?.tipo === ConversationTypeEnum.Prospect) {
      client = this.conversation?.cliente?.nomeFantasia
        || this.conversation?.cliente?.nome
        || this.conversation?.prospect?.fantasia
        || this.conversation?.prospect?.razaoSocial;
    } else {
      client = this.conversation?.cliente?.nome;
    }

    return client;
  }

  get lastMessage(): ConversationMessageModel | undefined {
    return last(this.conversation.messages || []);
  }

  get messageText(): string {
    if (this.lastMessage) {
      if (this.lastMessage.type.code === ConversationMessageTypeEnum.Template) {
        return this.getTemplateText(this.lastMessage);
      }

      return this.lastMessage.message || this.lastMessage.type.name;
    }

    return '';
  }

  get messageIcon(): string {
    if (this.lastMessage) {
      switch (this.lastMessage.type.code) {
        case ConversationMessageTypeEnum.Image:
          return 'mdi-image';
        case ConversationMessageTypeEnum.Audio:
          return 'mdi-headphones';
        case ConversationMessageTypeEnum.Document:
          return 'mdi-file-document';
        case ConversationMessageTypeEnum.Video:
          return 'mdi-video';
        case ConversationMessageTypeEnum.Sticker:
          return 'mdi-sticker';
        case ConversationMessageTypeEnum.Voice:
          return 'mdi-microphone';
        case ConversationMessageTypeEnum.Button:
          return 'mdi-gesture-tap';
        case ConversationMessageTypeEnum.Contacts:
          return 'mdi-contacts';
        case ConversationMessageTypeEnum.Location:
          return 'map-marker';
        case ConversationMessageTypeEnum.Unknown:
        case ConversationMessageTypeEnum.Text:
        default:
          return '';
      }
    }

    return '';
  }

  get messagePrefix(): string {
    if (this.lastMessage && this.lastMessage.origin.id === ConversationMessageOriginEnum.Outgoing) {
      return `${this.$t('crm.chatListItem.label.you')}`;
    }

    return '';
  }

  get additionalInfo(): string {
    if (this.additionalInfoType === 'city') {
      const city = this.conversation?.cliente?.municipio || this.conversation?.prospect?.cidade;
      if (city && city.nome) {
        return `${city.nome} / ${city.siglaUF}`;
      }

      return '';
    }

    if (this.conversation.tipo === ConversationTypeEnum.Prospect) {
      return this.conversation?.prospect?.fantasia || this.conversation?.prospect?.razaoSocial;
    }
    return this.conversation?.cliente?.nome;
  }

  get statusMessageIcon(): string {
    switch (this.lastMessage?.status?.code) {
      case ConversationMessageStatusEnum.Dispatched:
      case ConversationMessageStatusEnum.Sent:
        return 'mdi-check';
      case ConversationMessageStatusEnum.Delivered:
      case ConversationMessageStatusEnum.Read:
      case ConversationMessageStatusEnum.Received:
        return 'mdi-check-all';
      case ConversationMessageStatusEnum.Error:
      case ConversationMessageStatusEnum.Failed:
      case ConversationMessageStatusEnum.Created:
        return 'mdi-alert-circle';
      case ConversationMessageStatusEnum.Deleted:
        return 'mdi-delete';
      default:
        return '';
    }
  }

  get statusMessageIconColor(): string {
    switch (this.lastMessage?.status?.code) {
      case ConversationMessageStatusEnum.Read:
      case ConversationMessageStatusEnum.Received:
        return 'primary';
      case ConversationMessageStatusEnum.Created:
        return 'orange';
      case ConversationMessageStatusEnum.Error:
      case ConversationMessageStatusEnum.Failed:
        return 'red';
      default:
        return 'dark';
    }
  }

  get phoneMask(): string {
    return this.conversation.numeroWhatsapp.length > 12 ? '+## (##) #####-####' : '+## (##) ####-####';
  }

  get dateFormat(): string {
    let date = '';
    if (!this.conversation.dataUltimaMensagem) {
      date = dayjs(this.conversation.dataInicio).utc(true).format().toString();
    } else {
      date = this.conversation.dataUltimaMensagem.toString();
    }
    if (date.includes(' ')) {
      date = dayjs(date).utc(true).format();
    }

    if (!dayjs().isSame(date, 'date')) {
      return dayjs(date).format('DD/MM/YYYY');
    }

    return date.substring(date.indexOf('T') + 1, date.lastIndexOf(':'));
  }

  get isYesterday(): boolean {
    return dayjs().subtract(1, 'day').isSame(this.conversation.dataUltimaMensagem, 'date');
  }

  get btnConversation(): string {
    if (this.conversation.prospect || (this.conversation.cliente
      && this.conversation.cliente.tipo === ClientTypeEnum.Prospect)) {
      return 'P';
    }
    if (this.conversation.cliente && (this.conversation.cliente.tipo === ClientTypeEnum.Client
      || this.conversation.cliente.type === ClientTypeEnum.Client)) {
      return 'C';
    }
    return '?';
  }

  private getTemplateText(message: ConversationMessageModel): string {
    if (!message.content) {
      return '';
    }

    const messageTemplate = message.content.data.template;
    const templateName = messageTemplate.Name as string;

    if (this.templates.length) {
      const template = this.templates.find((x) => x.waTemplateName === templateName);

      if (template) {
        let parsedMessage = template.textoModeloMensagem;

        if (messageTemplate.Components && isArray(messageTemplate.Components)) {
          const bodyComponents = (messageTemplate.Components as IKeyValue<string | IKeyValue[]>[]).find(
            (x) => x.Type === 'body',
          );

          if (bodyComponents) {
            (bodyComponents.Parameters as IKeyValue<string>[]).forEach((param: IKeyValue, index: number) => {
              parsedMessage = parsedMessage.replace(`{{${index + 1}}}`, param.Text);
            });
          }
        }

        return parsedMessage;
      }
    }

    return templateName;
  }
}
